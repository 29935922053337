export type TMUser = {
    uid: string
    email: string
    emailVerified: boolean
}

export function TMUserFromAPI(obj: any) : TMUser {
    return {
        uid: obj.id,
        email: obj.email,
        emailVerified: obj.email_verified
    }
}
