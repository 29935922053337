import { AuthActivityNames } from '@core/activities';
import { AuthClaims, AuthRoles } from '@core/auth';
import { getDevice, getPlatform, getUserAgent } from '@core/utils/env';
import { createActivity } from '@web/features/activities/create-activity';
import * as React from 'react';
import { TMApi } from '@web/services/tmapi';
import {
  AuthPayloads,
  authReducer,
  AuthState,
  AuthTypes,
  initialAuthState,
} from './auth.state';
import { TMUser } from './user';

export const AuthStateContext =
  React.createContext<AuthState>(initialAuthState);

export const AuthDispatchContext = React.createContext<React.Dispatch<{
  type: AuthTypes;
  payload?: AuthPayloads;
}> | null>(null);

export type AuthProviderProps = { children: React.ReactNode };
/**
 * # AuthProvider
 *
 * @remarks
 * Responsible for managing authentication state across the entire application.
 * Located in {@link _app.tsx}, this component will provide global auth state.
 *
 *
 * Use hook {@link useAuth} to access it's state and methods.
 *
 * @note {@link useUserData} is dependent upon this
 *
 * @export
 * @param {AuthProviderProps} { children }
 * @return {JSX.Element}
 */
export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(authReducer, initialAuthState);

  const handleAuthStateChange = React.useCallback(
    async (user: TMUser | null) => {
      if (!user) {
        // no user found
        return dispatch({ type: AuthTypes.ON_AUTH_FULFILLED });
      }

      const claims = {
        role: AuthRoles.USER,
        auth_time: new Date().getTime(),
        email: user.email,
        user_id: user.uid,
        email_verified: user.emailVerified,
      } as AuthClaims;

      const details = {
        user_agent: getUserAgent(),
        platform: getPlatform(),
        device: getDevice(),
        timezone_offset: new Date().getTimezoneOffset(),
      };

      // user has signed-in or is remembered
      await createActivity({
        uid: user.uid,
        config: { name: AuthActivityNames.SIGN_IN, details },
      });

      return dispatch({
        type: AuthTypes.ON_AUTH_FULFILLED,
        payload: { user, claims },
      });
    },
    [],
  );

  React.useEffect(() => {
    dispatch({ type: AuthTypes.ON_AUTH_FETCH });
    const tmapi = new TMApi();
    tmapi.restoreLogin(handleAuthStateChange);

    return () => {};
  }, [handleAuthStateChange]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}
