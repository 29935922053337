import { ActivityConfig } from '@core/activities';
import { TMApi } from '@web/services/tmapi';

export type CreateActivityProps = {
  uid: string;
  config: ActivityConfig;
  lastActive?: string | number;
};

/**
 * # createActivity
 *
 * Logs a user's event in firestore for future reference.
 *
 * If the app is in production, this will log the event to firebase
 *    analytics service
 *
 * If the app is in a `development` environemnt, this will log the
 *    event to the console
 *
 * @export
 * @param {CreateActivityProps} {
 *   uid,
 *   config,
 *   lastActive = Date.now(),
 * }
 * @return {*}
 */
export async function createActivity(_: CreateActivityProps) {
  // activity logging is currently disabled:
  // const activity = {
  //   ...createMetadata({ lastActive, uid }),
  //   name: config.name,
  //   details: 'details' in config ? config.details : null,
  // } as Activity;

  // Hey there, I broke time travel debugging functionality here, because the code wasn't great here
  // and it made working with a dedicated backend very difficult.
  // IMO it should be outsourced into either service workers or the backend, so you can manipulate the
  // user's properties this way.
  // If stumble upon this and want to restore time travel capabilities, talk to Thomas.
  // If you're Thomas, bad luck, you have to implement this yourself.

  const tmapi = new TMApi();
  await tmapi.updateUserDocument({ last_active: new Date().toISOString() });

  // Add an activity associated with the user and log important data
  // return db.collection(Collections.ACTIVITY).add(activity); //TODO(ts): implement
}
