import { UserData } from '@core/users/users';
import { WellnessCheckValues } from '@core/wellness';
import { TMUserFromAPI } from '@web/features/auth/user';
import { Meditation } from '@core/meditations';
import { Resource } from '@core/resources';
import { Hope } from '@core/hopes';
import { MessageInABottle } from '@core/message-in-a-bottle';
import { Recording } from './recording';
import { HopeQuery } from './queries';
import { WellnessCheckValuesWithCreation } from './wellness';

type Config = {
  apiBase: string;
};

type userDataListener = (_: UserData) => void;

type apiCtx = {
  userDataListeners: userDataListener[];
};

const context = { userDataListeners: [] } as apiCtx;

export class TMApi {
  public static onUserDataUpdate(fn: userDataListener) {
    context.userDataListeners.push(fn);
  }

  private static getApiBase(): string {
    return (
      process.env.NEXT_PUBLIC_TMAPI_BASE || `//${window.location.hostname}:8080`
    );
  }

  async signIn(username: string, password: string, shouldRemember = false) {
    return fetch(TMApi.getApiBase() + '/auth', {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    }).then((response) => {
      if (response.status != 200) {
        return response.text().then((respBody) => {
          throw new Error(
            'Login failed. Code: ' +
              response.status +
              '. Backend response: ' +
              respBody,
          );
        });
      }
      return response.json().then((obj) => {
        // TODO: handle null token
        this.setToken(obj.token, shouldRemember);
      });
    });
  }

  // restoreLogin checks if there is a login session and restores the session
  async restoreLogin(handleAuthStateChange: CallableFunction) {
    const token = this.getToken();
    if (token == null || token == '') {
      handleAuthStateChange(null);
      return null;
    }
    return fetch(TMApi.getApiBase() + '/me', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status != 200) {
          // token is probably toast, so let's reset it:
          this.deleteToken();

          handleAuthStateChange(null);
          return null;
        }
        return response.json().then((obj) => {
          let user = TMUserFromAPI(obj);
          handleAuthStateChange(user);
          return user;
        });
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  getUserDocument(): Promise<UserData> {
    return this.call('/me/document', 'GET');
  }

  getToken(): string | null {
    let token = window.localStorage.getItem('tmapi_token');
    if (token == null) {
      token = window.sessionStorage.getItem('tmapi_token');
    }
    if (token != null && !token?.startsWith('sess')) {
      console.log(
        "Token does not start with sess, which means that it is corrupt or invalid, let's ignore it.",
      );
      this.deleteToken();
      return null;
    }
    return token;
  }

  setToken(token: string, longlivedSession: boolean) {
    const storage = longlivedSession
      ? window.localStorage
      : window.sessionStorage;
    storage.setItem('tmapi_token', token);
  }

  deleteToken() {
    window.sessionStorage.removeItem('tmapi_token');
    window.localStorage.removeItem('tmapi_token');
  }

  getHeaders(): Record<string, string> {
    let token = this.getToken();
    if (token == '') {
      return {};
    }
    return {
      Authorization: 'Bearer ' + token,
    };
  }

  addWellness(wellness: WellnessCheckValues): Promise<any> {
    return this.call('/wellness', 'POST', wellness);
  }

  getWellnessChecks(): Promise<WellnessCheckValuesWithCreation[]> {
    return this.call('/wellness', 'GET').then((resp) => {
      return resp['wellness_checks'];
    });
  }

  updateUserDocument(update: Partial<UserData>): Promise<any> {
    return this.call('/me/document', 'PATCH', update).then((updatedDoc) => {
      context.userDataListeners.forEach((fn) => {
        fn(updatedDoc);
      });
    });
  }

  call(path: string, method: string, data?: any): Promise<any> {
    const req: RequestInit = {
      method,
      headers: this.getHeaders(),
    };
    if (data && method !== 'GET') {
      req.body = JSON.stringify(data);
    } else if (data && method === 'GET') {
      path += '?' + new URLSearchParams(data).toString();
    }

    return fetch(TMApi.getApiBase() + path, req).then((response) => {
      if (response.status >= 400) {
        throw new Error('received error from API: ' + response.statusText);
      }
      if (response.status === 204) {
        return null;
      }
      return response.json().then((obj) => {
        return obj;
      });
    });
  }

  getRecording(id: string): Promise<Recording> {
    return this.call('/recordings/' + id, 'GET');
  }

  getRecordings(): Promise<Recording[]> {
    return this.call('/recordings', 'GET').then((response) => {
      return response['recordings'];
    });
  }

  updateRecording(id: string, update: Partial<Recording>) {
    return this.call('/recordings/' + id, 'PATCH', update);
  }

  createUploadTicket(collection: string): Promise<string> {
    return this.call('/upload-ticket/' + collection, 'POST').then(
      (response) => {
        return response['upload_url'];
      },
    );
  }

  uploadRecording(uploadURL: string, file: File, meta: any): Promise<string> {
    // uploadURL assumes a URL that can be directly used for uploading (e.g. presigned S3 URL)
    return fetch(uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    }).then((_) => {
      meta['upload_url'] = uploadURL;
      return this.call('/recordings', 'POST', meta).then((response) => {
        return response['recording_id'];
      });
    });
  }

  getMeditations(type: string, category?: string): Promise<Meditation[]> {
    let q: { type: string; category?: string } = { type: type };
    if (category != null) {
      q.category = category;
    }
    return this.call('/meditations?' + new URLSearchParams(q), 'GET').then(
      (response) => {
        return response['meditations'];
      },
    );
  }

  getMeditationById(id: string): Promise<Meditation> {
    return this.call('/meditations/' + id, 'GET').then((response) => {
      return response['meditation'];
    });
  }

  uploadMeditation(uploadURL: string, file: File, meta: any): Promise<string> {
    return this.uploadFileAndPost(uploadURL, '/meditations', file, meta).then(
      (resp) => {
        return resp['meditation_id'];
      },
    );
  }

  uploadMeditationBackground(file: File): Promise<string> {
    return this.createUploadTicket('meditation_backgrounds').then(
      (uploadURL) => {
        return this.upload(uploadURL, file).then((_) => {
          return uploadURL;
        });
      },
    );
  }

  deleteMeditation(id: string) {
    return this.call('/meditations/' + id, 'DELETE');
  }

  uploadFileAndPost(
    uploadURL: string,
    postPath: string,
    file: File,
    meta: any,
  ): Promise<any> {
    return this.upload(uploadURL, file).then((_) => {
      meta['upload_url'] = uploadURL;
      return this.call(postPath, 'POST', meta).then((response) => {
        return response;
      });
    });
  }

  upload(uploadURL: string, file: File): Promise<Response> {
    return fetch(uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
  }

  getResources(query: any): Promise<Resource[]> {
    return this.call('/resources', 'GET', query).then((response) => {
      return response['resources'];
    });
  }

  getHopes(query: HopeQuery): Promise<Hope[]> {
    return this.call('/hopes', 'GET', query).then((response) => {
      return response['hopes'];
    });
  }

  getHopeCount(query: HopeQuery): Promise<number> {
    return this.call('/hopes', 'GET', query).then((response) => {
      return response['page']['count'];
    });
  }

  getHope(hopeID: string): Promise<Hope> {
    return this.call('/hopes/' + hopeID, 'GET').then((response) => {
      return response['hope'];
    });
  }

  updateHope(hopeID: string, update: Partial<Hope>): Promise<Hope> {
    return this.call('/hopes/' + hopeID, 'PATCH', update).then((response) => {
      return response['hope'];
    });
  }

  addHope(hope: Hope): Promise<Hope> {
    return this.call('/hopes', 'POST', hope).then((response) => {
      return response['hope'];
    });
  }

  deleteHope(hopeID: string): Promise<any> {
    return this.call('/hopes/' + hopeID, 'DELETE').then((_) => {
      return;
    });
  }

  reportHope(hopeID: string): Promise<any> {
    return this.call('/hopes/' + hopeID + '/reports', 'POST').then((_) => {
      return;
    });
  }

  getWaterCount(): Promise<number> {
    return this.call('/water', 'GET').then((response) => {
      return response['count'];
    });
  }

  addWater(): Promise<number> {
    return this.call('/water', 'POST').then((response) => {
      return response['count'];
    });
  }

  resetPassword(email: string): Promise<any> {
    return this.call('/password-reset', 'POST', { email: email });
  }

  setPassword(token: string, newPassword: string): Promise<any> {
    return this.call('/password', 'POST', {
      token: token,
      password: newPassword,
    });
  }

  createUserAccount(
    email: string,
    password: string,
    utm?: Record<string, string>,
  ): Promise<any> {
    return this.call('/account', 'POST', {
      email,
      password,
      utm,
    }).then((response) => {
      this.setToken(response['token'], false);
    });
  }

  confirmEmail(token: string): Promise<any> {
    return this.call('/account/email-confirmation', 'POST', {
      token,
    });
  }

  logout(): Promise<void> {
    const token = this.getToken();
    this.deleteToken();
    if (token) {
      return this.call('/sessions', 'DELETE', { token });
    }
    return Promise.resolve();
  }

  sendSupportMessage(msg: string, email: string): Promise<void> {
    return this.call('/email', 'POST', { message: msg, email });
  }

  setNewsletterSubStatus(status: string): Promise<void> {
    return this.call('/newsletter', 'POST', { status });
  }

  getMessageInABottle(id: string): Promise<MessageInABottle> {
    return this.call(`/bottle/${id}`, 'GET');
  }

  shareMessageInABottle(recordingID: string): Promise<MessageInABottle> {
    return this.call(`/bottle`, 'POST', { recordingID });
  }

  sendUTMPoint(url: string, utm?: Record<string, string>, ref?: string) {
    return this.call('/utm', 'POST', {
      url,
      utm,
      ref,
    });
  }

  requestAccountDeletion(): Promise<void> {
    return this.call('/account', 'DELETE');
  }
}
