import { TMApi } from '@web/services/tmapi';
import { AdminActivityNames, AuthActivityNames } from '@core/activities';
import { CreateAdminConfig } from '@core/auth';
import { createActivity } from '@web/features/activities/create-activity';
import { TILTSwal } from '@web/services/swal';
import { useRouter } from 'next/router';
import * as React from 'react';
import { AuthDispatchContext, AuthStateContext } from './auth.context';
import { AuthStatus, AuthTypes } from './auth.state';

/**
 *
 * # useAuth
 *
 * A hook to provides access to {@link AuthStateContext} state and actions.
 * Must be a child of {@link AuthStateContext.Provider }.
 *
 * @export
 * @return {[
 *   state,
 *   { signIn, signUp, signOut, resetPassword, verifyEmail, createAdmin },
 * ]}
 */
export function useAuth() {
  const router = useRouter();

  const state = React.useContext(AuthStateContext);
  const dispatch = React.useContext(AuthDispatchContext);

  const tmapi = new TMApi();

  if (
    typeof state === 'undefined' ||
    !state ||
    typeof dispatch === 'undefined' ||
    !dispatch
  ) {
    throw new Error(
      'useAuth must be used inside of a `AuthStateContext.Provider`. Please wrap this component in an `AuthStateContext.Provider` component',
    );
  }

  /**
   * # signUp
   *
   * Creates an account with the given credentials.
   * Will trigger the `onAuthStateChanged` in {@link useAuth}
   */
  const signUp = async (email: string, password: string, redirectTo = '/') => {
    const utmParams: Record<string, string> = {};
    const ss = window.sessionStorage;

    ['utm_source', 'utm_campaign'].forEach((utm_key) => {
      const v = ss.getItem(utm_key);
      if (v) {
        utmParams[utm_key] = v;
      }
    });

    await tmapi.createUserAccount(email, password, utmParams).then((_) => {
      return tmapi.restoreLogin(dispatch);
    });
    if (redirectTo) {
      await router.push(redirectTo);
    }
    router.reload();
  };

  const signIn = async (
    email: string,
    password: string,
    shouldRemember = false,
    redirectTo: string | null = '/',
  ) => {
    return tmapi.signIn(email, password, shouldRemember).then((_) => {
      return tmapi.restoreLogin(dispatch).then((user) => {
        if (redirectTo) {
          return router.push(redirectTo);
        }
        dispatch({
          type: AuthTypes.ON_AUTH_FULFILLED,
          payload: { user },
        });
        state.isAuthenticated = true;
        state.status = AuthStatus.FULFILLED;
        return true;
      });
    });

    // state.isAuthenticated = true;
    // state.status = AuthStatus.FULFILLED

    // await setPersitance(shouldRemember);
    // const { user } = await auth.signInWithEmailAndPassword(email, password);

    // if (!user) {
    //   throw new Error(
    //     'There was a problem signing in to your account. Please try again later.',
    //   );
    // }

    // const details = {
    //   user_agent: getUserAgent(),
    //   platform: getPlatform(),
    //   device: getDevice(),
    //   timezone_offset: new Date().getTimezoneOffset(),
    // };

    // await createActivity({
    //   uid: user.uid,
    //   config: { name: AuthActivityNames.SIGN_IN, details },
    // });
  };

  /**
   * # signOut
   *
   *  @note We have to log this event before calling log out because the
   *      current `firestore.rules` would restrict us from accessing the db.
   */
  const signOut = async () => {
    if (!state.user?.uid) {
      throw new Error('No user found. Please login.');
    }

    await createActivity({
      uid: state.user.uid,
      config: { name: AuthActivityNames.SIGN_OUT },
    });

    await tmapi.logout();
    dispatch({ type: AuthTypes.ON_AUTH_RESET });
    state.isAuthenticated = false;
    state.status = AuthStatus.IDLE;
    return router.push('/');
  };

  /**
   * # changeEmail
   *
   * Allows a user to change their email
   */
  const changeEmail = async (_email: string) => {
    await TILTSwal.fire({
      title: 'Error',
      text: 'Sorry, the function is currently unavailable.',
      icon: 'error',
    }); // todo(ts)
    // await auth.currentUser?.updateEmail(email);
    // await TILTSwal.fire({
    //   icon: 'success',
    //   title: <p css={{ textAlign: 'center' }}>Successfull</p>,
    //   html: (
    //     <div css={{ textAlign: 'center' }}>
    //       <p>Your email has been successfully changed</p>
    //     </div>
    //   ),
    // });
  };

  /**
   * # resetPassword
   *
   * Sends an reset email to the specified email address if
   */
  const resetPassword = async (email: string) => {
    await tmapi.resetPassword(email);

    await TILTSwal.fire({
      icon: 'success',
      title: <p css={{ textAlign: 'center' }}>Reset Email Sent!</p>,
      html: (
        <div css={{ textAlign: 'center' }}>
          <p>Check your email to reset your password</p>
        </div>
      ),
    });
  };

  /**
   * # verifyEmail
   *
   * Sends a request for a user to verify their email address.
   */
  const verifyEmail = async () => {
    TILTSwal.fire({
      title: 'Error',
      text: 'Currently unavailable',
      icon: 'error',
    });

    if (!state.user?.uid) {
      throw new Error('No user found. Please login.');
    }

    await createActivity({
      uid: state.user.uid,
      config: { name: AuthActivityNames.VERIFY_EMAIL },
    });

    await TILTSwal.fire({
      icon: 'success',
      title: <p css={{ textAlign: 'center' }}>Email verification Sent!</p>,
      html: (
        <div css={{ textAlign: 'center' }}>
          <p>Check your email to verify your email</p>
        </div>
      ),
    });
  };

  /**
   * # createAdmin
   *
   * Calls our `onCreateAdmin` callable function to create an admin user
   *  permissions granting
   *
   */
  const createAdmin = async (config: CreateAdminConfig) => {
    throw new Error('Currently unavailable'); //todo(ts)
    const onCreateAdmin = functions.httpsCallable('default-auth-onCreateAdmin');

    const { data } = await onCreateAdmin(config);

    if (!state.user?.uid) {
      throw new Error('No user found. Please login.');
    }

    await createActivity({
      uid: state.user.uid,
      config: { name: AdminActivityNames.CREATE_ADMIN, details: config },
    });

    await TILTSwal.fire({
      icon: 'success',
      title: <p css={{ textAlign: 'center' }}>Admin User Created!</p>,
      html: (
        <div css={{ textAlign: 'center' }}>
          <p>{JSON.stringify(data, null, 2)}</p>
        </div>
      ),
    });
  };

  return [
    state,
    {
      signIn,
      signUp,
      signOut,
      changeEmail,
      resetPassword,
      verifyEmail,
      createAdmin,
    },
  ] as const;
}
